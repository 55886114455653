<template>
  <div>
    <div class="gva-table-box">
      <div class="gva-btn-list">
        <el-button size="small" type="primary" icon="plus" @click="addUser">新增账号</el-button>
      </div>
      <el-table :data="tableData" max-height="800">
        <el-table-column align="left" label="id" width="50" prop="id" />
        <el-table-column align="left" label="账号" min-width="100" prop="userName" />
        <el-table-column align="left" label="昵称" min-width="100" prop="nickName">
          <template #default="scope">
            <p v-if="!scope.row.editFlag" class="nickName">{{ scope.row.nickName }}
              <el-icon class="pointer" color="#66b1ff" @click="openEdit(scope.row)">
                <edit />
              </el-icon>
            </p>
            <p v-if="scope.row.editFlag" class="nickName">
              <el-input v-model.trim="scope.row.nickName" />
              <el-icon class="pointer" color="#67c23a" @click="enterEdit(scope.row)">
                <check />
              </el-icon>
              <el-icon class="pointer" color="#f23c3c" @click="closeEdit(scope.row)">
                <close />
              </el-icon>
            </p>
          </template>
        </el-table-column>
        <el-table-column align="left" label="手机" min-width="100" prop="nickName">
          <template #default="{row}">
        <p v-if="!row.editFlagP" class="nickName">{{ row.mobilePhone }}
          <el-icon class="pointer" color="#66b1ff" @click="openEditP(row)">
            <edit />
          </el-icon>
        </p>
        <p v-else class="nickName">
          <el-input v-model.trim="row.mobilePhone" />
          <el-icon class="pointer" color="#67c23a" @click="enterEditP(row)">
            <check />
          </el-icon>
          <el-icon class="pointer" color="#f23c3c" @click="closeEditP(row)">
            <close />
          </el-icon>
        </p>
          </template>
        </el-table-column>


        <el-table-column align="left" label="所属公司" min-width="150">
          <template #default="scope">
            <el-cascader v-model="scope.row.devCompanyId" :options="companyOptions" :show-all-levels="false"
              collapse-tags
              :props="{ multiple: false, checkStrictly: true, label: 'companyName', value: 'companyId', disabled: 'disabled', emitPath: false }"
              :clearable="false" @visible-change="(flag) => { changeCompany(scope.row, flag) }" />
          </template>
        </el-table-column>

        <el-table-column align="left" label="账号角色" min-width="200">
          <template #default="scope">
            <el-cascader v-model="scope.row.authorityIds" :options="authOptions" :show-all-levels="false" collapse-tags
              :props="{ multiple: true, checkStrictly: true, label: 'authorityName', value: 'authorityId', disabled: 'disabled', emitPath: false }"
              :clearable="false" @visible-change="(flag) => { changeAuthority(scope.row, flag) }"
              @remove-tag="() => { changeAuthority(scope.row, false) }" />
          </template>
        </el-table-column>
        <el-table-column label="详情" type="expand" width="60">
          <template #default="props">
            <el-row>
              <el-col :span="1" />
              <!--              <el-col :span="4"> 手机:{{ props.row.mobilePhone }} </el-col>-->
              <el-col :span="4"> email:{{ props.row.email }} </el-col>
              <el-col :span="4"> 创建时间:{{ formatDate(props.row.createdat) }} </el-col>
              <el-col :span="4"> 修改时间:{{ formatDate(props.row.updatedat) }} </el-col>
              <el-col :span="6"> UUID:{{ props.row.uuid }} </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="120">
          <template #default="scope">
            <el-button plain type="primary" icon="magic-stick" size="small" @click="resetPassword(scope.row)">重置密码</el-button>
            <el-popover :visible="scope.row.visible" placement="top" width="160">
              <p>确定要删除此账号吗</p>
              <div style="text-align: right; margin-top: 8px;">
                <el-button size="small" text @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" size="small" @click="deleteUser(scope.row)">确定</el-button>
              </div>
              <template #reference>
                <el-button plain type="warning" icon="delete" size="small">删除</el-button>
              </template>
            </el-popover>          
          </template>
        </el-table-column>

      </el-table>
      <div class="gva-pagination">
        <el-pagination :current-page="page" :page-size="pageSize" :page-sizes="[10, 30, 50, 100]" :total="total"
          layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
          @size-change="handleSizeChange" />
      </div>
    </div>
    <el-dialog v-model="addUserDialog" custom-class="user-dialog" title="新增账号">
      <el-form ref="userForm" :rules="rules" :model="userInfo" label-width="80px">
        <el-form-item label="账号" prop="username">
          <el-input v-model.trim="userInfo.username" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model.trim="userInfo.password" />
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model.trim="userInfo.nickName" />
        </el-form-item>
        <el-form-item label="账号角色" prop="authorityId">
          <el-cascader v-model="userInfo.authorityIds" style="width:100%" :options="authOptions"
            :show-all-levels="false"
            :props="{ multiple: true, checkStrictly: true, label: 'authorityName', value: 'authorityId', disabled: 'disabled', emitPath: false }"
            :clearable="false" />
        </el-form-item>
        <el-form-item label="手机号" prop="mobilePhone">
          <el-input v-model.trim="userInfo.mobilePhone" />
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input v-model.trim="userInfo.email" />
        </el-form-item>
        <el-form-item label="所属公司" prop="devCompanyId">
          <el-cascader v-model="userInfo.devCompanyId" style="width:100%" :options="companyOptions"
            :show-all-levels="false"
            :props="{ multiple: false, checkStrictly: true, label: 'companyName', value: 'companyId', disabled: 'disabled', emitPath: false }"
            :clearable="false" />
        </el-form-item>
<!--        <el-form-item label="头像" label-width="80px">-->
<!--          <div style="display:inline-block" @click="openHeaderChange">-->
<!--            <img v-if="userInfo.headerImg" class="header-img-box"-->
<!--              :src="(userInfo.headerImg && userInfo.headerImg.slice(0, 4) !== 'http') ? path + userInfo.headerImg : userInfo.headerImg">-->
<!--            <div v-else class="header-img-box">从媒体库选择</div>-->
<!--          </div>-->
<!--        </el-form-item>-->

      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeAddUserDialog">取 消</el-button>
          <el-button size="small" type="primary" @click="enterAddUserDialog">确 定</el-button>
        </div>
      </template>
    </el-dialog>
    <ChooseImg ref="chooseImg" :target="userInfo" :target-key="`headerImg`" />
  </div>
</template>

<script>
import { getUserInfo } from '@/api/user'
import { getCompany } from '@/api/company'

// 获取列表内容封装在mixins内部  getTableData方法 初始化已封装完成
const path = process.env.VUE_APP_BASE_API
import {
  getUserList,
  setUserAuthorities,
  register,
  deleteUser
} from '@/api/user'
import { getAuthorityList } from '@/api/authority'
import infoList from '@/mixins/infoList'
import { mapGetters } from 'vuex'
import ChooseImg from '@/components/chooseImg/index.vue'
//import warningBar from '@/components/warningBar/warningBar.vue'
import { setUserInfo, resetPassword } from '@/api/user.js'
import { formatDate } from '@/utils/format'

export default {
  name: 'Api',
  components: { ChooseImg},
  mixins: [infoList],
  data() {
    return {
      listApi: getUserList,
      path: path,
      authOptions: [],
      addUserDialog: false,
      backNickName: '',
      userInfo: {
        username: '',
        password: '',
        nickName: '',
        headerImg: '',
        authorityId: '',
        authorityIds: [],
        devCompanyId: '',
        // companyIds: [],
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 5, message: '最低5位字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入账号密码', trigger: 'blur' },
          { min: 6, message: '最低6位字符', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入账号昵称', trigger: 'blur' }
        ],
        authorityId: [
          { required: true, message: '请选择账号角色', trigger: 'blur' }
        ],
        devCompanyId: [
          { required: true, message: '请选择所属公司', trigger: 'blur' }
        ],
      },
      companyOptions: [],
      temporaryCompany: '',
      temporaryAuthorities: [],
    }
  },
  computed: {
    ...mapGetters('user', ['token']),

  },
  watch: {
    tableData() {
      this.setAuthorityIds()
     // this.setCompanyIds()
    },
  },
  async created() {
    this.$route.params
    // await this.getId2Company()
    await this.getTableData()
    await this.setCompanyOptions()
    const res = await getAuthorityList({ page: 1, pageSize: 999 })
    this.setOptions(res.data.list)
  },
  methods: {
    async setCompanyOptions() {
      const res = await getCompany({})
      if (res.code === 0) {
        let l = res.data.list
        let d = []
        for (let i of l) {
          // TODO 这里需要扩展公司简称
          d.push(
            {
              companyName: i.name,
              companyId: i.id,
            }
          )
        }
        this.companyOptions = d
      }
    },
    equalSets(a, b) {
      if (a.size !== b.size) {
        return false
      }
      for (var i of a) {
        if (!b.has(i)) {
          return false
        }
      }
      return true
    },

    resetPassword(row) {
      this.$confirm(
        '是否将此账号密码重置为123456?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(async () => {
        const res = await resetPassword({
          id: row.id,
        })
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      })
    },
    setAuthorityIds() {
      this.tableData && this.tableData.forEach((user) => {
        const authorityIds = user.authorities && user.authorities.map(i => {
          return i.authorityId
        })
        user.authorityIds = authorityIds
      })
    },
    // setCompanyIds() {
    //   this.tableData.forEach(
    //     (user) => {
    //       user.companyIds = [user.devCompanyId]
    //     }
    //   )
    // },
    openHeaderChange() {
      this.$refs.chooseImg.open()
    },
    setOptions(authData) {
      this.authOptions = []
      this.setAuthorityOptions(authData, this.authOptions)
    },
    openEdit(row) {
      this.backNickName = row.nickName
      row.editFlag = true
    },
    async enterEdit(row) {
      const res = await setUserInfo({ nickName: row.nickName, id: row.id })
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '设置成功'
        })
      }
      this.backNickName = ''
      row.editFlag = false
    },
    closeEdit(row) {
      row.nickName = this.backNickName
      this.backNickName = ''
      row.editFlag = false
    },
    // 修改手机
    openEditP(row) {
      this.backMobilePhone = row.mobilePhone
      row.editFlagP = true
    },
    async enterEditP(row) {
      const res = await setUserInfo({ mobilePhone: row.mobilePhone, id: row.id })
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '设置成功'
        })
      }
      this.backMobilePhone = ''
      row.editFlagP = false
    },
    closeEditP(row) {
      row.mobilePhone = this.backMobilePhone
      this.backMobilePhone = ''
      row.editFlagP = false
    },
    // 修改邮箱
    openEditM(row) {
      this.backEmail = row.email
      row.editFlagM = true
    },
    async enterEditM(row) {
      const res = await setUserInfo({ email: row.email, id: row.id })
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '设置成功'
        })
      }
      this.backEmail = ''
      row.editFlagM = false
    },
    closeEditM(row) {
      row.email = this.backEmail
      this.backEmail = ''
      row.editFlagM = false
    },
    setAuthorityOptions(AuthorityData, optionsData) {
      AuthorityData &&
        AuthorityData.forEach(item => {
          if (item.children && item.children.length) {
            const option = {
              authorityId: item.authorityId,
              authorityName: item.authorityName,
              children: []
            }
            this.setAuthorityOptions(item.children, option.children)
            optionsData.push(option)
          } else {
            const option = {
              authorityId: item.authorityId,
              authorityName: item.authorityName
            }
            optionsData.push(option)
          }
        })
    },
    async deleteUser(row) {
      const res = await deleteUser({ id: row.id })
      if (res.code === 0) {
        this.$message.success('删除成功')
        await this.getTableData()
        row.visible = false
      }
    },
    async enterAddUserDialog() {
      this.userInfo.authorityId = this.userInfo.authorityIds[0]
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          const res = await register(this.userInfo)
          if (res.code === 0) {
            this.$message({ type: 'success', message: '创建成功' })
          }
          await this.getTableData()
          this.closeAddUserDialog()
        }
      })
    },
    closeAddUserDialog() {
      this.$refs.userForm.resetFields()
      this.userInfo.headerImg = ''
      this.userInfo.authorityIds = []
      this.addUserDialog = false
    },
    addUser() {
      this.addUserDialog = true
    },
    async changeAuthority(row, flag) {
      if (flag) {
        this.temporaryAuthorities = new Set(row.authorityIds)
      } else {
        if (this.equalSets(this.temporaryAuthorities, new Set(row.authorityIds))) {
          return
        }
        this.$nextTick(async () => {
          const res = await setUserAuthorities({
            id: row.id,
            authorityIds: row.authorityIds
          })
          if (res.code === 0) {
            this.$message({ type: 'success', message: '角色设置成功' })
          }
        })
      }
    },
    async changeCompany(row, flag) {
      if (flag) { // flag 为 true 表示 cascader 出现时，false 表示消失时
        this.temporaryCompany = row.devCompanyId
      } else {
        if (this.temporaryCompany === row.devCompanyId) {
          return
        }
        this.$nextTick(async () => {
          const res = await setUserInfo({
            id: row.id,
            devCompanyId: row.devCompanyId,
          })
          if (res.code === 0) {
            this.$message({ type: 'success', message: '公司设置成功' })
          }
        })
      }
    },
    editMobilePhone(row) {
      setUserInfo({
        id: row.id,
        devCompanyId: row.devCompanyId,
      }).then(r=>{
        if (r.code === 0) {
          this.$message({ type: 'success', message: '公司设置成功' })
        }
      })
    },
  }
}
</script>

<style lang="scss">
.user-dialog {
  .header-img-box {
    width: 200px;
    height: 200px;
    border: 1px dashed #ccc;
    border-radius: 20px;
    text-align: center;
    line-height: 200px;
    cursor: pointer;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

.nickName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pointer {
  cursor: pointer;
  font-size: 16px;
  margin-left: 2px;
}
</style>
